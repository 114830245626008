import React from "react";
import Amplify, { I18n } from "aws-amplify";
import { ConsoleLogger as Logger } from "@aws-amplify/core";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { GlobalStyle, TopNav, Footer } from "@ewe-it/ewe-design-react";
import { getUrlParameter, filterEweHostname } from "@ewe-it/ewe-frontend-utils";
import styled from "@emotion/styled";

import Authenticator from "./authenticator";
import SignOut from "./sign-out";
import dict from "../dict";
import { redirectStatusCode500 } from "../utils";
//import MemoryStorage from "../memory-storage";

const logger = new Logger("App");

let redirectUri = filterEweHostname(getUrlParameter("redirect_uri"));
let clientId = getUrlParameter("client_id");
const slimmed = getUrlParameter("slimmed");
const redirectToUriAfterLogout = filterEweHostname(
  getUrlParameter("logout_uri"),
);

// Kunden erhalten manchmal Briefe bei denen Sie dazu aufgefordert werden
// ewe.de/signup oder login.ewe.de/signup zu besuchen. ewe.de/signup ist dabei
// lediglich eine Weiterleitung auf login.ewe.de/signup.
//
// Bei solchen Aufrufen fehlen dann client_id und redirect_uri im query string.
// Daher müssen für beide Parameter Fallback-Werte verwendet werden.
if (window.location.hostname === "login.ewe.de") {
  // Für login.ewe.de wird die clientId im query string ignoriert um
  // zu vermeiden, dass eine falsche ClientId verwendet wird (z.B. die von KON).
  clientId = "20lvbf35ag2e52lvu3mi59brhr";

  if (!redirectUri) {
    // Fallback für die redirectUri. Da es sich um frische Kunden handelt soll
    // direkt auf Contract Connect weitergeleitet werden.
    redirectUri = "https://auth-connect.ewe.de/";
  } else if (
    // Weiter wird hier vermieden, dass der Redirect der produktiven Umgebung
    // durch das Übergeben einer falschen redirect_uri versehentlich auf
    // die Staging-Umgebung zeigt.
    redirectUri ===
    "https://meine-ewe-k.ewe.de/ewe/frontendredirects/csslogin.html"
  ) {
    redirectUri = "https://meine.ewe.de/ewe/frontendredirects/csslogin.html";
  }
} else if (window.location.hostname === "login-k.ewe.de") {
  // Für login.ewe.de wird die clientId im query string ignoriert um
  // zu vermeiden, dass eine falsche ClientId verwendet wird (z.B. die von KON).
  clientId = "rq45om66hhrvd3qg54165sl30";

  if (!redirectUri) {
    // Fallback für die redirectUri. Da es sich um frische Kunden handelt soll
    // direkt auf Contract Connect weitergeleitet werden.
    redirectUri = "https://auth-connect-k.ewe.de/";
  } else if (
    // Weiter wird hier vermieden, dass der Redirect der produktiven Umgebung
    // durch das Übergeben einer falschen redirect_uri versehentlich auf
    // die Staging-Umgebung zeigt.
    redirectUri === "https://meine.ewe.de/ewe/frontendredirects/csslogin.html"
  ) {
    redirectUri =
      "https://meine-ewe-k.ewe.de/ewe/frontendredirects/csslogin.html";
  }
} else if (window.location.hostname === "localhost") {
  // Für login.ewe.de wird die clientId im query string ignoriert um
  // zu vermeiden, dass eine falsche ClientId verwendet wird (z.B. die von KON).
  clientId = "6a88u9hcntvju4g4h7l0kvv767";

  if (!redirectUri) {
    // Fallback für die redirectUri. Da es sich um frische Kunden handelt soll
    // direkt auf Contract Connect weitergeleitet werden.
    redirectUri = "http://localhost:3002/";
  }
}

const PageContainer = styled.div`
  position: relative;
  min-height: 100vh;
`;

const ContentWrapper = styled.div`
  padding-bottom: 60px;
  @media (min-width: 768px) {
    padding-bottom: 800px;
  }
`;

const FooterContainer = styled.div`
  width: 100%;
  @media (min-width: 768px) {
    position: absolute;
    bottom: 0;
    left: 0;
  }
`;

try {
  Amplify.configure({
    Auth: {
      region: "eu-central-1",
      userPoolId: process.env.REACT_APP_USER_POOL_ID,
      userPoolWebClientId: clientId,
      //storage: MemoryStorage,
    },
    API: {
      endpoints: [
        {
          name: "AuthPortalServiceAPI",
          endpoint: process.env.REACT_APP_API_ENDPOINT,
        },
      ],
    },
  });
} catch (error) {
  logger.error(error);
  redirectStatusCode500();
}

I18n.putVocabularies(dict);

I18n.setLanguage("de");

const originalGet = I18n.get;

I18n.get = (key, defVal) => {
  if (
    key.match(
      /at 'userName' failed to satisfy constraint: Member must satisfy regular expression pattern:/,
    )
  ) {
    key = "Incorrect username or password";
  }
  return originalGet(key, defVal);
};

function App() {
  return (
    <>
      <GlobalStyle />
      <PageContainer>
        <ContentWrapper>
          <TopNav
            hasMobileMenu={false}
            logoHref={slimmed ? "#" : "https://ewe.de"}
          />
          <Router>
            <Switch>
              <Route
                exact
                path="/oauth2/authorize"
                render={() => (
                  <Authenticator
                    validationData={{ redirectUri }}
                    initialAuthState="signIn"
                  />
                )}
              />
              <Route
                exact
                path="/signup"
                render={() => (
                  <Authenticator
                    validationData={{ redirectUri }}
                    initialAuthState="signUp"
                  />
                )}
              />
              <Route
                exact
                path="/logout"
                render={() => (
                  <SignOut
                    redirectToUriAfterLogout={redirectToUriAfterLogout}
                  />
                )}
              />
              <Route
                exact
                path="/forgot-username"
                render={() => (
                  <Authenticator
                    validationData={{ redirectUri }}
                    initialAuthState="forgotUsername"
                  />
                )}
              />
              <Route
                exact
                path="/confirm-signup"
                render={() => (
                  <Authenticator
                    validationData={{ redirectUri }}
                    initialAuthState="confirmSignUp"
                  />
                )}
              />
              <Route
                render={() => {
                  window.location = "https://www.ewe.de";
                  return null;
                }}
              />
            </Switch>
          </Router>
        </ContentWrapper>
        {slimmed ? null : (
          <FooterContainer>
            <Footer />
          </FooterContainer>
        )}
      </PageContainer>
    </>
  );
}

export default App;
