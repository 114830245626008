import React, { useEffect } from "react";
import { Auth } from "aws-amplify";
import { ConsoleLogger as Logger } from "@aws-amplify/core";
import { Loading } from "aws-amplify-react";

import { redirectStatusCode500, storeTokens } from "../utils";
import { getUrlParameter } from "@ewe-it/ewe-frontend-utils";
import { oneLineTrim } from "common-tags";

const logger = new Logger("Redirect");

function Redirect({ authState, validationData, authData }) {
  useEffect(() => {
    async function redirect() {
      try {
        const session = await Auth.currentSession();

        const idToken = session.getIdToken().getJwtToken();
        const accessToken = session.getAccessToken().getJwtToken();
        const refreshToken = session.getRefreshToken().getToken();

        const expiration =
          session.getIdToken().getExpiration() -
          session.getIdToken().getIssuedAt();

        const state = getUrlParameter("state");
        const authorizationCode = getUrlParameter("authorization_code");

        const { redirectUri } = validationData;

        const expire = new Date(
          (session.getIdToken().getIssuedAt() + 60 * 60 * 24) * 1000,
        );
        const secure = process.env.REACT_APP_COOKIE_DOMAIN !== "localhost";
        const expires = expire.toGMTString();

        // Set the cookies so users are signed in immediately after they
        // log into their account.
        // These cookies are also set in custom-sign-up.js for fresh accounts.
        const loginSessionCookie = oneLineTrim`
          loginsession=loggedin;
          expires=${expires};
          domain=${process.env.REACT_APP_COOKIE_DOMAIN};
          path=/;
          ${secure ? "secure;" : ""}
          SameSite=lax
        `;
        document.cookie = loginSessionCookie;

        if (authData && authData.attributes && authData.attributes.sub) {
          const loginSubCookie = oneLineTrim`
            loginsub=${authData.attributes.sub};
            expires=${expires};
            domain=${process.env.REACT_APP_COOKIE_DOMAIN};
            path=/;
            ${secure ? "secure;" : ""}
            SameSite=lax
          `;
          document.cookie = loginSubCookie;
        }

        const stateQS = state ? `&state=${state}` : "";

        /**
         * PKCE Flow
         */
        if (authorizationCode && redirectUri) {
          // Store tokens in backend
          const response = await storeTokens(
            authorizationCode,
            idToken,
            accessToken,
            refreshToken,
          );

          if (response.status === 200) {
            window.location.replace(
              `${redirectUri}?code=${authorizationCode}${stateQS}`,
            );
          } else {
            console.error(
              "Could not store tokens. Server response: ",
              await response.text,
            );
          }
        } else if (redirectUri) {
          window.location.replace(
            `${redirectUri}#id_token=${idToken}&access_token=${accessToken}&expires_in=${expiration}&token_type=Bearer&state=${state}`,
          );
        }
      } catch (error) {
        logger.error(error);
        redirectStatusCode500();
      }
    }

    if (authState === "signedIn") {
      redirect();
    }
  }, [authState, validationData, authData]);

  return authState === "signedIn" ? <Loading /> : null;
}

export default Redirect;
