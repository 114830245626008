export default {
  de: {
    "1 validation error detected: Value at 'username' failed to satisfy constraint: Member must satisfy regular expression pattern: [\\p{L}\\p{M}\\p{S}\\p{N}\\p{P}]+":
      "Bitte beachten Sie, dass Ihr Benutzername keine Leerzeichen enthalten darf.",
    "2 validation errors detected: Value ' ' at 'userName' failed to satisfy constraint: Member must satisfy regular expression pattern: [\\p{L}\\p{M}\\p{S}\\p{N}\\p{P}]+; Value ' ' at 'userAlias' failed to satisfy constraint: Member must satisfy regular expression pattern: [\\p{L}\\p{M}\\p{S}\\p{N}\\p{P}]+":
      "Bitte beachten Sie, dass Ihr Benutzername keine Leerzeichen enthalten darf.",
    "Create a new account": "Jetzt registrieren & neuen Account erstellen",
    Email: "E-Mail",
    "Confirm Email": "E-Mail bestätigen",
    "Have an account? ": "Bereits registriert? ",
    "Create Account": "Jetzt erstellen",
    "The following fields need to be filled out: Username, Password, Email":
      "Bitte füllen Sie diese Felder aus: Benutzername, Passwort, E-Mail",
    "The following fields need to be filled out: Password, Email":
      "Bitte füllen Sie diese Felder aus: Passwort, E-Mail",
    "The following fields need to be filled out: Username, Email":
      "Bitte füllen Sie diese Felder aus: Benutzername, E-Mail",
    "The following fields need to be filled out: Username, Password":
      "Bitte füllen Sie diese Felder aus: Benutzername, Passwort",
    "The following fields need to be filled out: Password":
      "Bitte füllen Sie diese Felder aus: Passwort",
    "The following fields need to be filled out: Username":
      "Bitte füllen Sie diese Felder aus: Benutzername",
    "The following fields need to be filled out: Email":
      "Bitte füllen Sie diese Felder aus: E-Mail",
    "The following fields need to be filled out: Username, Password, Email, Confirm Email":
      "Bitte füllen Sie diese Felder aus: Benutzername, Passwort, E-Mail, E-Mail bestätigen",
    "The following fields need to be filled out: Password, Email, Confirm Email":
      "Bitte füllen Sie diese Felder aus: Passwort, E-Mail, E-Mail bestätigen",
    "The following fields need to be filled out: Username, Email, Confirm Email":
      "Bitte füllen Sie diese Felder aus: Benutzername, E-Mail, E-Mail bestätigen",
    "The following fields need to be filled out: Username, Password, Confirm Email":
      "Bitte füllen Sie diese Felder aus: Benutzername, Passwort, E-Mail bestätigen",
    "The following fields need to be filled out: Password, Confirm Email":
      "Bitte füllen Sie diese Felder aus: Passwort, E-Mail bestätigen",
    "The following fields need to be filled out: Username, Confirm Email":
      "Bitte füllen Sie diese Felder aus: Benutzername, E-Mail bestätigen",
    "The following fields need to be filled out: Email, Confirm Email":
      "Bitte füllen Sie diese Felder aus: E-Mail, E-Mail bestätigen",
    "The following fields need to be filled out: Confirm Email":
      "Bitte füllen Sie diese Felder aus: E-Mail bestätigen",
    "Invalid password format":
      "Dieses Passwort ist nicht sicher genug. Bitte mindestens 8 Zeichen eingeben",
    "Invalid email address format.":
      "Bitte geben Sie eine gültige E-Mail-Adresse ein",
    "Lost your code? ": "Sie haben Ihren Code verloren? ",
    "New Password": "Bitte geben Sie Ihr neues Passwort ein",
    "Enter your email": "Bitte geben Sie Ihre E-Mail-Adresse ein",
    "Enter your registered email":
      "Bitte geben Sie Ihre registrierte E-Mail-Adresse ein",
    Code: "Bestätigungs-Code",
    Confirm: "Jetzt bestätigen",
    Submit: "Jetzt abschicken",
    Request: "Jetzt anfordern",
    "Invalid verification code provided, please try again.":
      "Dieser Code ist ungültig, bitte versuchen Sie es noch einmal.",
    "Sign in to your account": "Bitte melden Sie sich an",
    "Enter your username": "Bitte geben Sie Ihren Benutzernamen ein",
    "Enter your password": "Bitte geben Sie Ihr Passwort ein",
    "Enter your code": "Geben Sie bitte Ihren Code ein",
    "No account? ": "Sie haben noch keinen Account? ",
    "Create account": "Jetzt registrieren",
    "Sign In": "Jetzt anmelden",
    "null failed with error Generate callenges lambda cannot be called..":
      "Der Benutzername oder das Passwort ist nicht korrekt",
    "User does not exist":
      "Der Benutzername oder das Passwort ist nicht korrekt",
    "User already exists":
      "Dieser Benutzername ist bereits vergeben, bitte einen anderen auswählen.",
    "Incorrect username or password":
      "Der Benutzername oder das Passwort ist nicht korrekt",
    "Custom auth lambda trigger is not configured for the user pool.":
      "Der Benutzername oder das Passwort ist nicht korrekt",
    "Send Code": "Jetzt Code senden",
    "Username/client id combination not found.":
      "Dieser Benutzername ist nicht registriert",
    "Code cannot be empty": "Bitte geben Sie den Bestätigungs-Code ein.",
    "Password cannot be empty": "Bitte geben Sie Ihr Passwort ein",
    "Password did not conform with policy: Password not long enough":
      "Das Passwort ist nicht lang genug",
    "Password did not conform with policy: Password must have lowercase characters":
      "Das Passwort muss Kleinbuchstaben enthalten",
    "Password did not conform with policy: Password must have uppercase characters":
      "Das Passwort muss Großbuchstaben enthalten",
    "Password did not conform with policy: Password must have numeric characters":
      "Das Passwort muss Ziffern enthalten",
    "Password did not conform with policy: Password must have symbol characters":
      "Bitte verwenden Sie für die Sicherheit des Passworts mindestens eines der Sonderzeichen: ^$*.?})]-[({\"!_@#%&/<,\\>':;|~`",
    "Code resent!": "Code erneut versendet!",
    "Code sent!": "Code versendet!",
    "Attempt limit exceeded, please try after some time.":
      "Es wurde zu häuftig ein ungültiges Passwort eingegeben. Versuchen Sie es doch später nochmal.",
    "Cannot reset password for the user as there is no registered/verified email or phone_number":
      "Das Kennwort für den Benutzer kann nicht zurückgesetzt werden, da keine registrierte / bestätigte E-Mail-Adresse oder Telefonnummer vorhanden ist",
    "We've improved the security of your login for you. Please reset your password once for technical reasons":
      "Wir haben die Sicherheit Ihres Logins für Sie verbessert. Bitte setzen Sie aus technischen Gründen einmalig Ihr Passwort zurück.",
    "Password does not conform to policy: Password must have uppercase characters":
      "Dieses Passwort ist nicht sicher genug. Bitte verwenden Großbuchstaben.",
    "Password does not conform to policy: Password must have lowercase characters":
      "Dieses Passwort ist nicht sicher genug. Bitte verwenden Kleinbuchstaben.",
    "Password does not conform to policy: Password must have numeric characters":
      "Dieses Passwort ist nicht sicher genug. Bitte verwenden Sie Ziffern.",
    "Password does not conform to policy: Password must have symbol characters":
      "Dieses Passwort ist nicht sicher genug. Bitte verwenden Sie Sonderzeichen.",
    "The username should either be a string or one of the sign in types":
      "Bitte geben Sie Ihren Benutzernamen ein.",
    "Password attempts exceeded":
      "Leider haben Sie das Limit jetzt überschritten, versuchen Sie es bitte später nochmal.",
    "The verification code has been sent to your specified email address.":
      "Der Bestätigungs-Code wurde an Ihre angegebene E-Mail-Adresse gesendet.",
    "Please enter the confirmation code that we have sent to your e-mail address.":
      "Geben Sie dazu bitte den Bestätigungs-Code ein, den wir an Ihre E-Mail-Adresse geschickt haben.",
    "Your password was changed successfully.":
      "Ihr Passwort wurde erfolgreich geändert.",
  },
};
